var services = require('./ideaServices');

window.ideaForms = {};

$(function() {
    $('.ideaForm').each(function() {
        window.ideaForms[this.id] = new IdeaForm($(this));
    });
});

function IdeaForm($element) {
    this.$element = $element;
    this.zoneId = this.$element.attr('data-zoneid');
    this.detailUrl = $element.attr('data-detailurl');

    $('form', this.$element).submit(this.handleSubmit.bind(this));
}

IdeaForm.prototype.handleSubmit = function(evt) {
    evt.preventDefault();

    $('.error', this.$element).remove();
    $('button, input[type="submit"], input[type="button"]', this.$element).prop('disabled', true);

    var title = $('[name="ideaTitle"]', this.$element).val();
    var body = $('[name="ideaBody"]', this.$element).val();
    var category = $('[name="ideaCategory"]', this.$element).val().trim() || '';

    if (!title) {
        $('<p class="error">Title is required</p>').insertBefore($('[name="ideaTitle"]', this.$element));
    }

    if (!body) {
        $('<p class="error">Body is required</p>').insertBefore($('[name="ideaBody"]', this.$element));
    }

    if (!title || !body) {
        $('button, input[type="submit"], input[type="button"]', this.$element).prop('disabled', false);
        return;
    }

    body = body.split('\n').map(function(line) {
        if (!line || line.trim() === '') return '';

        var div = $('<div>').append($('<p>').text(line));
        return div.html();
    }).join('');

    services.putIdea(this.zoneId, title, body, category, function(ideaId) {
        $('button, input[type="submit"], input[type="button"]', this.$element).prop('disabled', false);

        $('[name="ideaTitle"]', this.$element).val('');
        $('[name="ideaBody"]', this.$element).val('');

        var detailUrl = this.detailUrl;
        if (detailUrl.indexOf('?') === -1) {
            detailUrl += '?ideaId=';
        } else {
            detailUrl += '&ideaId=';
        }
        detailUrl += ideaId;

        window.location.assign(detailUrl);
    }.bind(this));
};