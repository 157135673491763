var serviceName = 'ocms_comm_MyAccountService';

module.exports = {
    putUserData: function(contactName, email, phone, mobilePhone, cb) {
        var params = {
            service: serviceName,
            action: 'putUserData',
            contactName: contactName,
            email: email,
            phone: phone,
            mobilePhone: mobilePhone,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);

                if (!result.success) {
                    throw result.message;
                }

                cb();
            },
        });
    },
};