module.exports = function(str, noEscape) {
    if (!str) return [];

    var fn = noEscape ? 'html' : 'text';

    return str.split('\n').map(function(line) {
        if (line.trim() === '') {
            return $('<p>&nbsp;</p>');
        }

        return $('<p>')[fn](line);
    });
};