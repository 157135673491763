var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// IE8 compatibility
function prepareDateString(dateStr) {
    if(dateStr) {
        var d = dateStr;
        var parts = d.match(/\d+/g);
        var isoTime = Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        var isoDate = new Date(isoTime);
        return isoDate;
    }
}

function formatDateObject(d, type) {
    if(d) {
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        if(hours === 0) hours = 12;
        if(minutes < 10) minutes = '0'+minutes;

        var month = d.getMonth() + 1;
        if(month < 10) month = '0' + month;

        var stringDate = '';
        if (type === 'full') {
            stringDate = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' '
                + hours + ':' + minutes + ' ' + ampm;
        } else {
            stringDate = d.getDate() + '/' + month + '/' + d.getFullYear() + ' '
                + hours + ':' + minutes + ' ' + ampm;
        }

        return stringDate;
    }
}

function formatDateString(dateStr, type) {
    return formatDateObject(prepareDateString(dateStr), type);
}

module.exports = {
    prepareDateString: prepareDateString,
    formatDateObject: formatDateObject,
    formatDateString: formatDateString,
};