module.exports = {};

$(function() {
    $('body').on('click', '.accordion-heading', function(evt) {
        var $container = $(this).parents('.accordion').first();

        if ($container.length === 0) {
            console.error('Could not find an accordion container for: ', this);
            return;
        }

        var $content = $('.accordion-content', $container);

        if ($content.is(':animated')) {
            // No queuing animations!
            return;
        }

        if ($container.hasClass('accordion-expanded')) {
            $content.slideUp(200, function() {
                $container.removeClass('accordion-expanded');
            });
        } else {
            $container.addClass('accordion-expanded');
            $content.slideDown(200);
        }
    });
});