module.exports = function(textarea) {
    sizeTextarea(textarea);

    $(textarea).keyup(function(evt) {
        sizeTextarea(evt.target);
    });
};

function sizeTextarea(textarea) {
    var $textarea = $(textarea);
    var lines = $textarea.val().split('\n').length;
    var baseFontSize = 16;
    var height =
        (1.2 * baseFontSize) // Padding
        + (1.3 * baseFontSize * lines); // text
    $textarea.css('height', height + 'px');
}