module.exports = {
    generateVoteDetails: function(idea, basis) {
        var voteDetails = $('<div class="voteDetails">');

        if (idea.Votes) {
            var voteElement = idea.Votes.records[0].Type === 'Up'
                ? $('<i class="promoted fa fa-thumbs-up" aria-hidden="true">').attr('title', basis.promotedText)
                : $('<i class="demoted fa fa-thumbs-down" aria-hidden="true">').attr('title', basis.demotedText);
            voteDetails.append(
                voteElement,
                $('<span>').text(' ' + idea.VoteTotal + ' ' + basis.pointsText)
            );
        } else {
            voteDetails.append(
                $('<div>').text(idea.VoteTotal + ' ' + basis.pointsText),
                $('<button class="voteButton" data-vote="Up" data-ideaid="' + idea.Id + '">')
                    .attr('aria-label', basis.promoteButtonText)
                    .append('<i class="fa fa-thumbs-o-up" aria-hidden="true"></i>'),
                $('<button class="voteButton" data-vote="Down" data-ideaid="' + idea.Id + '">')
                    .attr('aria-label', basis.demoteButtonText)
                    .append('<i class="fa fa-thumbs-o-down" aria-hidden="true"></i>')
            );
        }

        return voteDetails;
    }
};