var services = require('./myAccountServices');
var interactionApi = require('../../api/interactionApi');

$(document).ocmsDynamicLoadFinished(function() {
    var $myAccountForm = $('.myAccount');

    if ($myAccountForm.length > 1) {
        console.error('Multiple my account forms found - there can only be one!');
        return;
    }

    if ($myAccountForm.length === 1) {
        window.ideaDetail = new MyAccountForm($myAccountForm);
    }
});

function MyAccountForm($element) {
    this.$element = $element;

    $('.passwordForm', this.$element).submit(this.handlePasswordSubmit.bind(this));
    $('.infoForm', this.$element).submit(this.handleInfoSubmit.bind(this));
}

MyAccountForm.prototype.handlePasswordSubmit = function(evt) {
    evt.preventDefault();

    var passwordForm = evt.target;

    var values = {};

    $('[name]', passwordForm).each(function(i, e) {
        values[e.name] = e.value;
    });

    $('.success, .error', passwordForm).remove();
    $('button', passwordForm).prop('disabled', true);

    interactionApi.doChangePassword(values.currentPassword, values.newPassword, values.verifyPassword, function(result) {
        if (result.success) {
            $('input', passwordForm).val('');
            $(passwordForm).prepend(
                $('<p class="success">').text('Password changed successfully.')
            );
        } else {
            $(passwordForm).prepend(
                $('<p class="error">').text(result.message)
            );
        }

        $('button', passwordForm).prop('disabled', false);
    });
};

MyAccountForm.prototype.handleInfoSubmit = function(evt) {
    evt.preventDefault();

    var infoForm = evt.target;

    var values = {};

    $('[name]', infoForm).each(function(i, e) {
        values[e.name] = e.value;
    });

    $('.success, .error', infoForm).remove();
    $('button', infoForm).prop('disabled', true);

    services.putUserData(values.contactName, values.email, values.phone, values.mobilePhone, function() {
        $(infoForm).prepend(
            $('<p class="success">').text('Your account information has been updated.')
        );

        $('button', infoForm).prop('disabled', false);
    });
};