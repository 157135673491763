$(function() {
    var $container = $('.lms-container');
    if ($container.length === 1) {
        $Lightning.use('c:ocms_comm_LMS', function() { // Load the Lightning App ocms_comm_LMS
            $Lightning.createComponent(
                'ilms:ImpactLMS', // Load the Lightning Component ilms:IL_Learn_Full
                {}, // ...with no attributes passed in
                'lms-container', // ...into the DOM element with id=lms-container
                function(cmp) {$('.loading', $container).remove()} // Once the component is loaded 
            );
        });
    } else if ($container.length) {
        $container.text('There are multiple LMS containers on this page - this should not be!');
    }
});
