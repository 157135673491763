var services = require('./caseServices');

window.caseHomeLists = {};

$(document).ocmsDynamicLoadFinished(function() {
    $('.caseHomeList').each(function() {
        window.caseHomeLists[this.id] = new CaseHomeList($(this));
    });
});

function CaseHomeList($element) {
    this.$element = $element;
    this.detailUrl = $element.attr('data-detailurl');

    this.update();
}

CaseHomeList.prototype.update = function() {
    $('.caseData', this.$element).empty().addClass('loading');
    $('.withoutCases', this.$element).hide();
    $('.withCases', this.$element).hide();
    services.getCaseList('OrchestraCMS', 'Waiting for Customer', 'all', 5, 0, this.render.bind(this));
};

CaseHomeList.prototype.render = function(cases) {
    var $caseList = $('.caseData', this.$element).empty().removeClass('loading');

    var detailUrlStem = this.detailUrl;
    if (detailUrlStem.indexOf('?') === -1) {
        detailUrlStem += '?caseId=';
    } else {
        detailUrlStem += '&caseId=';
    }

    cases.forEach(function(caseData) {
        $caseList.append(
            $('<li>').append(
                $('<a>').prop('href', detailUrlStem + caseData.id).text('Case ' + caseData.caseNumber),
                $('<span>').text(' - ' + caseData.subject)
            )
        );
    }.bind(this));

    if (cases.length) {
        $('.withoutCases', this.$element).hide();
        $('.withCases', this.$element).show();
    } else {
        $('.withoutCases', this.$element).show();
        $('.withCases', this.$element).hide();
    }
};