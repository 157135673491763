var ideaService = 'ocms_comm_IdeaService';

module.exports = {
    getIdeaList: function(zoneId, filters, order, count, page, cb) {
        var params = {
            service: ideaService,
            action: 'getIdeaList',
            zoneId: zoneId,
            category: filters.category,
            status: filters.status,
            userId: filters.userId,
            order: order,
            search: filters.search,
            count: count,
            page: page,
        };

        doServiceRequest(params, {
            readonly: true,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.ideas, result.pages);
            },
        });
    },

    getIdeaDetail: function(ideaId, cb) {
        var params = {
            service: ideaService,
            action: 'getIdeaDetail',
            ideaId: ideaId,
        };

        doServiceRequest(params, {
            readonly: true,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.idea);
            },
        })
    },

    putIdea: function(zoneId, title, body, category, cb) {
        var params = {
            service: ideaService,
            action: 'putIdea',
            zoneId: zoneId,
            title: title,
            ideaBody: body,
            category: category,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.ideaId);
            }
        });
    },

    putIdeaVote: function(ideaId, vote, cb) {
        var params = {
            service: ideaService,
            action: 'putIdeaVote',
            ideaId: ideaId,
            vote: vote,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.idea);
            }
        })
    },

    putIdeaComment: function(ideaId, comment, cb) {
        var params = {
            service: ideaService,
            action: 'putIdeaComment',
            ideaId: ideaId,
            comment: comment,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.idea);
            },
        });
    },
};