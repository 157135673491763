var unboundServices = require('./caseServices');

window.unboundCaseHomeLists = {};

$(document).ocmsDynamicLoadFinished(function() {
    $('.unboundCaseHomeList').each(function() {
        window.unboundCaseHomeLists[this.id] = new UnboundCaseHomeList($(this));
    });
});

function UnboundCaseHomeList($element) {
    this.$element = $element;
    this.detailUrl = $element.attr('data-detailurl');

    this.update();
}

UnboundCaseHomeList.prototype.update = function() {
    $('.unboundCaseData', this.$element).empty().addClass('loading');
    $('.unboundWithoutCases', this.$element).hide();
    $('.unboundWithCases', this.$element).hide();
    unboundServices.getCaseList('Unbound', 'Waiting for Customer', 'all', 5, 0, this.render.bind(this));
};

UnboundCaseHomeList.prototype.render = function(cases) {
    var $caseList = $('.unboundCaseData', this.$element).empty().removeClass('loading');

    var detailUrlStem = this.detailUrl;
    if (detailUrlStem.indexOf('?') === -1) {
        detailUrlStem += '?caseId=';
    } else {
        detailUrlStem += '&caseId=';
    }

    cases.forEach(function(caseData) {
        $caseList.append(
            $('<li>').append(
                $('<a>').prop('href', detailUrlStem + caseData.id).text('Case ' + caseData.caseNumber),
                $('<span>').text(' - ' + caseData.subject)
            )
        );
    }.bind(this));

    if (cases.length) {
        $('.unboundWithoutCases', this.$element).hide();
        $('.unboundWithCases', this.$element).show();
    } else {
        $('.unboundWithoutCases', this.$element).show();
        $('.unboundWithCases', this.$element).hide();
    }
};