var caseService = 'ocms_comm_CaseService';

module.exports = {
    getCaseList: function(caseRecordType, statusFilter, priorityFilter, count, page, cb) {
        var theService = (caseRecordType == 'Unbound' || window.location.href.toLowerCase().indexOf('unbound') !== -1) ? 'ocms_bld_CaseService' : caseService;
        var params = {
            service: theService,
            action: 'getCaseList',
            type: caseRecordType,
            status: statusFilter,
            priority: priorityFilter,
            count: count,
            page: page,
        };

        doServiceRequest(params, {
            readonly: true,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.cases, result.pages);
            },
        });
    },

    getCaseDetail: function(caseId, cb) {
        var params = {
            service: caseService,
            action: 'getCaseDetail',
            caseId: caseId,
        };

        doServiceRequest(params, {
            readonly: true,
            cb: function(json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.case);
            },
        })
    },

    putCaseComment: function(caseId, commentBody, cb) {
        var params = {
            service: caseService,
            action: 'putCaseComment',
            caseId: caseId,
            commentBody: commentBody,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function (json) {
                var result = JSON.parse(json);
                if (!result.success) {
                    throw result.message;
                }

                cb(result.case);
            },
        });
    },

    postCase: function(caseDetail, cb) {
        var params = {
            service: caseService,
            action: 'postCase',
            'case': JSON.stringify(caseDetail),
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);
                cb(result);
            }
        });
    },
};