var services = require('./caseServices');
var dateUtils = require('../../util/dates');

window.caseLists = {};

$(function() {
    $('.caseList').each(function() {
        window.caseLists[this.id] = new CaseList($(this));
    });
});

function CaseList($element) {
    this.$element = $element;
    this.pageSize = $element.attr('data-pagesize') || 20;
    this.detailUrl = $element.attr('data-detailurl');
    this.columns = JSON.parse($element.attr('data-columns'));
    this.casePriorities = JSON.parse($element.attr('data-priorities')).reduce(function(valueToLabel, priority) {
        valueToLabel[priority.value] = priority.label;
        return valueToLabel;
    }, {});

    this.state = {
        statusFilter: $('.caseListStatus', $element).val() || 'all',
        priorityFilter: $('.caseListPriority', $element).val() || 'all',
        page: 0,
        pageCount: 0,
    };

    $('.caseListStatus', this.$element).change(this.handleChangeStatus.bind(this));
    $('.caseListPriority', this.$element).change(this.handleChangePriority.bind(this));
    $('.stgPreviousPage', this.$element).click(this.handleChangePage.bind(this, -1));
    $('.stgNextPage', this.$element).click(this.handleChangePage.bind(this, 1));

    this.update();
}

CaseList.prototype.update = function() {
    $('input, select, button', this.$element).prop('disabled', true);
    services.getCaseList(
        'OrchestraCMS',
        this.state.statusFilter,
        this.state.priorityFilter,
        this.pageSize,
        this.state.page,
        this.render.bind(this)
    );
};

CaseList.prototype.handleChangeStatus = function() {
    this.state.statusFilter = $('.caseListStatus', this.$element).val();
    this.state.page = 0;
    this.update();
};

CaseList.prototype.handleChangePriority = function() {
    this.state.priorityFilter = $('.caseListPriority', this.$element).val();
    this.state.page = 0;
    this.update();
};

CaseList.prototype.handleChangePage = function(changeInPage, domEvent) {
    domEvent.preventDefault();

    var newPage = this.state.page + changeInPage;

    if (newPage < 0) return;
    if (newPage >= this.state.pageCount) return;

    this.state.page = newPage;
    this.update();
};

CaseList.prototype.render = function(cases, pageCount) {
    this.state.pageCount = pageCount;

    var $caseList = $('.caseData', this.$element).empty();
    var columns = this.columns;

    var detailUrlStem = this.detailUrl;
    if (detailUrlStem.indexOf('?') === -1) {
        detailUrlStem += '?caseId=';
    } else {
        detailUrlStem += '&caseId=';
    }

    $('.paginationText', this.$element).text('Page ' + (this.state.page + 1) + ' of ' + this.state.pageCount);
    $('.stgPreviousPage', this.$element).prop('disabled', this.state.page === 0);
    $('.stgNextPage', this.$element).prop('disabled', this.state.page === this.state.pageCount - 1);

    cases.forEach(function(caseData) {
        var $row = $('<tr>');

        var caseStatusIcon;
        if (caseData.status === 'Closed') {
            caseStatusIcon = $('<i class="fa fa-hourglass-end closedCase" aria-hidden="true" title="Closed"></i>');
        } else {
            caseStatusIcon = $('<i class="fa fa-hourglass-half openCase" aria-hidden="true"></i>').prop('title', caseData.status);
        }

        columns.forEach(function(columnName) {
            if (columnName === 'status') {
                $row.append($('<td>').append(caseStatusIcon));
            } else if (columnName === 'lastModified') {
                $row.append($('<td>').text(dateUtils.formatDateString(caseData.lastModified)));
            } else if (columnName === 'postDate') {
                $row.append($('<td>').text(dateUtils.formatDateString(caseData.postDate)));
            } else if (columnName === 'priorityValue') {
                $row.append($('<td>').text(this.casePriorities[caseData.priorityValue]));
            } else if (columnName === 'caseNumber') {
                var url = detailUrlStem + caseData.id;

                $row.append($('<td>').append(
                    $('<a>').prop('href', url).text(caseData[columnName])
                ));
            } else {
                $row.append($('<td>').text(caseData[columnName]));
            }
        }.bind(this));

        $caseList.append($row);
    }.bind(this));

    $('input, select', this.$element).prop('disabled', false);
};