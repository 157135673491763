var services = require('./caseServices');
var dateUtils = require('../../util/dates');
var newLineToP = require('../../util/newLineToP');

var sizedTextarea = require('../../util/sizedTextarea');
require('../../util/accordion');

var tingle = require('../../../lib/tingle/tingle.js');

$(function() {
    var $caseDetail = $('.caseDetail');

    if ($caseDetail.length > 1) {
        console.error('Multiple case detail containers found - there can only be one!');
        return;
    }

    if ($caseDetail.length === 1) {
        window.caseDetail = new CaseDetail($caseDetail);
    }
});

function CaseDetail($element) {
    this.$element = $element;

    this.casePriorities = JSON.parse($element.attr('data-priorities')).reduce(function(valueToLabel, priority) {
        valueToLabel[priority.value] = priority.label;
        return valueToLabel;
    }, {});

    this.state = {
        currentCase: {},
        selectedPriority: '',
    };

    this.update();

    $('.changeCasePriority', this.$element).click(this.handleChangeCasePriority.bind(this));
    $('.casePrioritySelect', this.$element).change(this.handleConfirmCasePriority.bind(this));
    $('.casePrioritySelect', this.$element).blur(this.handleCancelCasePriority.bind(this));

    $('.changeCaseAdditionalCC', this.$element).click(this.handleChangeCaseAdditionalCC.bind(this));

    $('.newComment', this.$element).click(this.handleNewComment.bind(this));
    $('.newCommentForm', this.$element).submit(this.handleSubmitComment.bind(this));
    $('.newCommentForm', this.$element).on('reset', this.handleResetComment.bind(this));

    $('.newAttachment', this.$element).click(this.handleNewAttachment.bind(this));

    $('.newAttachmentFrame').load(this.handleAttachmentFrameLoad.bind(this));
}

CaseDetail.prototype.handleChangeCasePriority = function() {
    this.$element.addClass('editing');
};

CaseDetail.prototype.handleConfirmCasePriority = function() {
    this.$element.removeClass('editing').removeClass('editable');

    var newPriority = $('.casePrioritySelect', this.element).val();

    $('.priority', this.$element).text(this.casePriorities[newPriority]);

    services.postCase({
        id: this.state.currentCase.id,
        priorityValue: newPriority,
    }, function(success) {
        this.$element.addClass('editable');

        if (success) {
            this.state.currentCase.priorityValue = newPriority;
            this.render();
        } else {
            $('.casePrioritySelect', this.$element).val(this.currentCase.priorityValue);
            this.render();
        }
    }.bind(this));
};

CaseDetail.prototype.handleCancelCasePriority = function() {
    var $select = $('.casePrioritySelect', this.$element);

    if ($select.val() === this.state.currentCase.priorityValue) {
        this.$element.removeClass('editing');
        $('.casePrioritySelect', this.$element).val(this.state.currentCase.priorityValue);
    }
};

CaseDetail.prototype.handleChangeCaseAdditionalCC = function(evt) {
    evt.preventDefault();

    var $modalContent = $('<div>').append(
        '<h2>Additional Case Email Recipients</h2>',
        '<p>Please enter one email address per line. These people will be notified on any case updates.</p>',
        $('<textarea class="caseAdditionalCCInput">').text(
            this.state.currentCase.additionalCC.reduce(function(text, address) {
                return text + address + '\n';
            }.bind(this), '')
        )
    );

    var modalProps = {
        footer: true,
        stickyFooter: true,
        closeMethods: ['overlay', 'button', 'escape'],
        closeLabel: 'Cancel',
    };

    var modal = new tingle.modal(modalProps);
    $(modal.modalBox).addClass('pg authorText');

    modal.setContent($modalContent.html());

    sizedTextarea($('.caseAdditionalCCInput', modal.modalBox));

    modal.addFooterBtn('Save Changes', '', function() {
        this.handleSaveCaseAdditionalCC($('.caseAdditionalCCInput', modal.modalBox).val());
        modal.close();
    }.bind(this));

    modal.addFooterBtn('Cancel', 'cancelButton', function() {
        modal.close();
    });

    modal.open();
};

CaseDetail.prototype.handleSaveCaseAdditionalCC = function(ccText) {
    ccText = ccText || '';

    this.$element.removeClass('editable');

    var ccList = ccText.split('\n').filter(function(address) {
        return address.trim() != '';
    });

    services.postCase({
        id: this.state.currentCase.id,
        additionalCC: ccList,
    }, function(success) {
        this.$element.addClass('editable');

        if (success) {
            this.state.currentCase.additionalCC = ccList;
            this.render();
        }
    }.bind(this));
};

CaseDetail.prototype.handleNewComment = function(evt) {
    evt && evt.preventDefault();

    $('.newCommentForm').show().slideDown(200);

    var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;
    OCMS.smoothScrollTo('.newCommentForm', null, headerHeight);
};

CaseDetail.prototype.handleSubmitComment = function(evt) {
    evt.preventDefault();

    var commentBody = $('[name="commentBody"]', evt.target).val();
    $('button', evt.target).prop('disabled', true);

    services.putCaseComment(this.state.currentCase.id, commentBody, function(caseData) {
        $('button', evt.target).prop('disabled', false);
        $('.newCommentForm').slideUp(200);

        this.render(caseData);
    }.bind(this));
};

CaseDetail.prototype.handleResetComment = function() {
    $('.newCommentForm').slideUp(200);
};

CaseDetail.prototype.handleNewAttachment = function(evt) {
    evt && evt.preventDefault();

    $('.newAttachmentForm').show().slideDown(200);

    var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;
    OCMS.smoothScrollTo('.newAttachmentForm', null, headerHeight);
};

CaseDetail.prototype.handleAttachmentFrameLoad = function(evt) {
    var $attachmentFrame = $(evt.target);
    var message = $('.message', $attachmentFrame.contents()).text();

    if (message) {
        this.update();
        $attachmentFrame.parents('.newAttachmentForm').slideUp(200);
    }
};

CaseDetail.prototype.update = function() {
    var caseId;

    window.location.search.substr(1).split('&').map(function(param) {
        return param.split('=');
    }).forEach(function(param) {
        if(param[0] === 'caseId' || param[0] === 'recordId') {
            caseId = decodeURIComponent(param[1]);
        }
    });

    if (caseId === undefined) {
        console.error('No caseId in the URL');
        return;
    }

    services.getCaseDetail(caseId, this.render.bind(this));
};

CaseDetail.prototype.render = function(caseDetail) {
    if (caseDetail !== undefined) {
        this.state.currentCase = caseDetail;
    }

    $('.subject', this.$element).text(this.state.currentCase.subject);
    $('.caseNumber', this.$element).text(this.state.currentCase.caseNumber);
    $('.status', this.$element).text(this.state.currentCase.status);
    $('.casePriority', this.$element).text(this.casePriorities[this.state.currentCase.priorityValue]);
    $('.casePrioritySelect', this.$element).val(this.state.currentCase.priorityValue);
    $('.caseType', this.$element).text(this.state.currentCase.type);
    $('.origin', this.$element).text(this.state.currentCase.origin);
    $('.opened', this.$element).text(dateUtils.formatDateString(this.state.currentCase.postDate, 'full'));
    $('.modified', this.$element).text(dateUtils.formatDateString(this.state.currentCase.lastModified, 'full'));
    $('.additionalCC', this.$element).empty().append(
        this.state.currentCase.additionalCC.map(function(address) {
            return $('<div>').text(address);
        }.bind(this))
    );

    var $description = $('.description', this.$element).empty();
    $description.append(newLineToP(this.state.currentCase.description));

    if (this.state.currentCase.status !== 'Closed') {
        this.$element.addClass('editable');
    } else {
        this.$element.removeClass('editable');
    }

    $('.comments', this.$element).empty().append(this.state.currentCase.comments.map(function(comment) {
        return (
            $('<div class="caseComment">').append(
                $('<h4>').text(comment.createdBy + ' - ' + dateUtils.formatDateString(comment.postDate, 'full')),
                $('<div class="commentBody">').append(newLineToP(comment.body))
            )
        );
    }));

    $('.attachments', this.$element).empty().append(this.state.currentCase.attachments.map(function(attachment) {
        var authorText = attachment.createdBy + ' - ' + dateUtils.formatDateString(attachment.createdDate, 'full');

        return (
            $('<div class="caseAttachment">').append(
                $('<a>').prop('href', '/servlet/servlet.FileDownload?file=' + attachment.id)
                    .append($('<strong>').text(attachment.name)),
                $('<span>').text(' (' + authorText + ')')
            )
        );
    }));

    if (this.state.currentCase.attachments.length === 0) {
        $('.attachments', this.$element).append('<div class="caseAttachment">No attachments</div>');
    }

    var $attachmentIframe = $('.newAttachmentFrame');
    $attachmentIframe.prop('src', $attachmentIframe.attr('data-src') + this.state.currentCase.id);
};