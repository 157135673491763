var services = require('./ideaServices');
var ideaUtils = require('./shared');
var dateUtils = require('../../util/dates');
var urlParameters = require('../../util/urlParameters');

window.ideaLists = {};

$(function() {
    $('.ideaList').each(function() {
        window.ideaLists[this.id] = new IdeaList($(this));
    });
});

function IdeaList($element) {
    this.$element = $element;
    this.zoneId = this.$element.attr('data-zoneid');
    this.detailUrl = $element.attr('data-detailurl');
    this.pageSize = $element.attr('data-pagesize') || 20;
    this.pointsText = this.$element.attr('data-pointstext');
    this.promoteButtonText = this.$element.attr('data-promotebuttontext');
    this.demoteButtonText = this.$element.attr('data-demotebuttontext');
    this.promotedText = this.$element.attr('data-promotedtext');
    this.demotedText = this.$element.attr('data-demotedtext');

    this.state = {
        filters: {
            category: urlParameters.getParameter('category') || null,
            status: urlParameters.getParameter('status') || null,
            userId: urlParameters.getParameter('userId') || null,
            search: urlParameters.getParameter('search') || null
        },
        order: urlParameters.getParameter('order') || 'trending',
        page: urlParameters.getParameter('page') || 0,
        pageCount: 0
    };

    $('[data-filter]', this.$element).click(this.handleClickChangeFilter.bind(this));
    $('[data-order]', this.$element).click(this.handleClickChangeOrder.bind(this));
    $('.stgPreviousPage', this.$element).click(this.handleChangePage.bind(this, -1));
    $('.stgNextPage', this.$element).click(this.handleChangePage.bind(this, 1));
    this.$element.on('click', '.voteButton', this.handleVote.bind(this));

    this.update();
}

IdeaList.prototype.handleClickChangeFilter = function(evt) {
    evt.preventDefault();

    var newFilter = evt.target.dataset.filter;

    if (!newFilter) {
        this.state.filters = {
            category: null,
            status: null,
            userId: null,
            search: null
        };
    } else {
        newFilter = newFilter.split(':');
        if (this.state.filters[newFilter[0]] !== undefined) {
            this.state.filters[newFilter[0]] = newFilter[1];
        }
    }

    this.state.page = 0;

    this.update();
};

IdeaList.prototype.handleClickChangeOrder = function(evt) {
    evt.preventDefault();

    this.state.order = evt.target.dataset.order;
    this.state.page = 0;

    this.update();
};

IdeaList.prototype.handleChangePage = function(changeInPage, domEvent) {
    domEvent.preventDefault();

    var newPage = this.state.page + changeInPage;

    if (newPage < 0) return;
    if (newPage >= this.state.pageCount) return;

    this.state.page = newPage;
    this.update();
};

IdeaList.prototype.handleVote = function(evt) {
    evt.preventDefault();

    $('.voteButton', this.$element).prop('disabled', true);

    var vote = evt.target.dataset.vote;
    var ideaId = evt.target.dataset.ideaid;

    services.putIdeaVote(ideaId, vote, function() {
        this.update();
    }.bind(this));
};

IdeaList.prototype.update = function() {
    $('input, select, button', this.$element).prop('disabled', true);
    services.getIdeaList(
        this.zoneId,
        this.state.filters,
        this.state.order,
        this.pageSize,
        this.state.page,
        this.render.bind(this)
    );
};

IdeaList.prototype.render = function(ideas, pageCount) {
    $('input, select', this.$element).prop('disabled', false);

    this.state.pageCount = pageCount;

    var $ideaList = $('.ideaData', this.$element).empty();

    var detailUrlStem = this.detailUrl;
    if (detailUrlStem.indexOf('?') === -1) {
        detailUrlStem += '?ideaId=';
    } else {
        detailUrlStem += '&ideaId=';
    }

    $('.paginationText', this.$element).text('Page ' + (this.state.page + 1) + ' of ' + this.state.pageCount);
    $('.stgPreviousPage', this.$element).prop('disabled', this.state.page === 0);
    $('.stgNextPage', this.$element).prop('disabled', this.state.page === this.state.pageCount - 1);

    ideas.forEach(function(ideaData) {
        $ideaList.append(
            $('<div class="ideaSummary">').attr('data-ideaid', ideaData.Id).append(
                $('<a>').prop('href', detailUrlStem + ideaData.Id).append(
                    $('<h3>').text(ideaData.Title)
                ),
                $('<div class="column-wrapper">').append(
                    $('<div class="column width-8/12">').append(
                        $('<p>').html(ideaData.Body)
                    ),
                    $('<div class="column width-4/12 clearfix">').append(
                        ideaUtils.generateVoteDetails(ideaData, this)
                    )
                )
            )
        );
    }.bind(this));

    if (!ideas.length) {
        $ideaList.append('<p>No ideas found.</p>');
    }

    var activeFilters = [];
    for (var filterName in this.state.filters) {
        if (this.state.filters[filterName]) {
            activeFilters.push(filterName + ':' + this.state.filters[filterName]);
        }
    }

    $('[data-filter]', this.$element).each(function(i, e) {
        var $e = $(e);
        var filter = $e.attr('data-filter');

        if (activeFilters.length === 0 && filter === '') {
            $e.addClass('activeFilter');
        } else if (activeFilters.length && activeFilters.indexOf(filter) !== -1) {
            $e.addClass('activeFilter');
        } else {
            $e.removeClass('activeFilter');
        }
    }.bind(this));

    $('[data-order]', this.$element).each(function(i, e) {
        if (e.dataset.order === this.state.order) {
            $(e).addClass('activeFilter');
        } else {
            $(e).removeClass('activeFilter');
        }
    }.bind(this));
};
