var services = require('./ideaServices');
var ideaUtils = require('./shared');
var dateUtils = require('../../util/dates');
var newLineToP = require('../../util/newLineToP');
var urlParameters = require('../../util/urlParameters');

$(function() {
    var $ideaDetail = $('.ideaDetail');

    if ($ideaDetail.length > 1) {
        console.error('Multiple idea detail containers found - there can only be one!');
        return;
    }

    if ($ideaDetail.length === 1) {
        window.ideaDetail = new IdeaDetail($ideaDetail);
    }
});

function IdeaDetail($element) {
    this.$element = $element;

    this.pointsText = this.$element.attr('data-pointstext');
    this.promoteButtonText = this.$element.attr('data-promotebuttontext');
    this.demoteButtonText = this.$element.attr('data-demotebuttontext');
    this.promotedText = this.$element.attr('data-promotedtext');
    this.demotedText = this.$element.attr('data-demotedtext');

    this.state = { currentIdea: {} };

    $('.newCommentForm', this.$element).submit(this.handleSubmitComment.bind(this));
    this.$element.on('click', '.voteButton', this.handleVote.bind(this));

    this.update();
}

IdeaDetail.prototype.handleSubmitComment = function(evt) {
    evt.preventDefault();

    var commentBody = $('[name="commentBody"]', evt.target).val();
    $('[name="commentBody"]', evt.target).prop('disabled', true);
    $('button', evt.target).prop('disabled', true);

    services.putIdeaComment(this.state.currentIdea.Id, commentBody, function(ideaData) {
        $('[name="commentBody"]', evt.target).val();
        $('[name="commentBody"]', evt.target).prop('disabled', false).val('');
        $('button', evt.target).prop('disabled', false);

        this.render(ideaData);
    }.bind(this));
};

IdeaDetail.prototype.handleVote = function(evt) {
    evt.preventDefault();

    $('.voteButton', this.$element).prop('disabled', true);

    var vote = evt.target.dataset.vote;

    services.putIdeaVote(this.state.currentIdea.Id, vote, function(ideaData) {
        this.render(ideaData);
    }.bind(this));
};

IdeaDetail.prototype.update = function() {
    var ideaId = urlParameters.getParameter('ideaId');

    if (ideaId === undefined) {
        console.error('No ideaId in the URL');
        return;
    }

    services.getIdeaDetail(ideaId, this.render.bind(this));
};

IdeaDetail.prototype.render = function(ideaDetail) {
    if (ideaDetail !== undefined) {
        this.state.currentIdea = ideaDetail;
    }

    $('.title', this.$element).text(this.state.currentIdea.Title);
    $('.body', this.$element).empty().append(this.state.currentIdea.Body);
    $('.status', this.$element).text(this.state.currentIdea.Status);
    $('.author', this.$element).text(this.state.currentIdea.CreatedBy.Name);
    $('.postDate', this.$element).text(dateUtils.formatDateString(this.state.currentIdea.CreatedDate, 'full'));

    var voteDetails = ideaUtils.generateVoteDetails(this.state.currentIdea, this);
    $('.voteDetails').html(voteDetails.html());

    if (this.state.currentIdea.Comments) {
        $('.commentCount', this.$element).text(this.state.currentIdea.Comments.totalSize);
        $('.commentsList', this.$element).empty().append(this.state.currentIdea.Comments.records.map(function(comment) {
            return (
                $('<div class="ideaComment">').append(
                    $('<h4>').text(comment.CreatedBy.Name + ' - ' + dateUtils.formatDateString(comment.CreatedDate, 'full')),
                    $('<div class="commentBody">').append(newLineToP(comment.CommentBody, true))
                )
            );
        }))
    } else {
        $('.commentCount').text(0);
    }
};